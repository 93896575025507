import type { PartialVehicleData as VehicleData } from "shared/dist/types/vehicleData";

import { IMAGES_SERVER } from "@/config/settings";

export const CAIA_LOGO_URL = `https://actia.ia.parts/media/brands/90/C@IA.png`;

export const iframeSlidesLogin = [
    "https://www.actia.com/fr/solutions/equipements-services-aux-ateliers/diagnostic-multimarque",
    "https://www.actia.com/fr/solutions/equipements-services-aux-ateliers/equipements-de-garage",
];
export const iframeSlides = [
    "https://www.actia.com/fr/",
    "https://www.actia.com/fr/",
    "https://www.actia.com/fr/",
];
export const iframeSlidesCaridentifier = [
    {
        title: "MD360°",
        img: `https://actia.ia.parts/media/brands/90/C@IA.png`,
        makeURL: (vd: VehicleData): string =>
            `https://meetbox.ia.parts/${vd.Codif_Vin_PRF}`,
    },
];
